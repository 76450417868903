import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tenants } from "../tenant/tenants";
import { Users } from "../user/users";
import { Applications } from "../application/applications";
import { Groups } from "../group/groups";
import Overview from "../overview/Overview";
import { AccordionItem } from "../../components/Accordion/accordionItems";
import { Grants } from "../grant/grants";
import { OrgGrants } from "../grant/orggrants";

import { TenantGrants } from "../grant/tenantgrants";


export function UserDetails() {
  const params = useParams();

  const location = useLocation();
  //const { orgName } = location?.state ? location.state : [];
  const [selectedTab, setSelectedTab] = useState("");

  const tabItems = [
    {
      id: "usr-tenants",
      path: "/usr/tenants",
      label: "Tenants",
      tabPanelId: "tabpanel-tenants",
      selected: selectedTab === "tenants",
    },

    {
      id: "usr-groups",
      path: "/usr/groups",
      label: "groups",
      tabPanelId: "tabpanel-groups",
      selected: selectedTab === "groups",
    },
    {
      id: "usr-grants",
      path: "/usr/grants",
      label: "grants",
      tabPanelId: "tabpanel-grants",
      selected: selectedTab === "grants",
    },
  ];
  const navigateTo = (to) => {
    if (to.includes("tenants")) {
      setSelectedTab("tenants");
    } else if (to.includes("users")) {
      setSelectedTab("users");
    } else if (to.includes("apps")) {
      setSelectedTab("apps");
    } else if (to.includes("groups")) {
      setSelectedTab("groups");
    } else {
      setSelectedTab("tenants");
    }
  };
  const { state } = useLocation();
  const [activeAccordion, setActiveAccordion] = useState("");
  const tenantId =
    state?.myObj["urn:avalara:schemas:extension:2.0:AvalaraUser"]?.defaultTenant
      ?.identifier;

  return (
    <>
      <Overview />
      <div className="detail-info">
    <h2>
        {state?.myObj?.displayName || state?.myObj?.userName || ""}
    </h2>
    <div>
        <b>Name:</b>{" "}
        {(state?.myObj?.name?.givenName || "") + 
         (state?.myObj?.name?.familyName ? " " + state?.myObj?.name?.familyName : "") || 
         ""}
    </div>
    <div>
        <b>Email:</b> {state?.myObj?.emails?.[0]?.value || ""}
    </div>
</div>


      <div>
        <AccordionItem
          title="Tenants"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="tenants"
          iconName="catalog"
        >
          <Tenants id={tenantId} userId={params.id} from="usr" />
        </AccordionItem>
        <AccordionItem
          title="Groups"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="groups"
          iconName="users"
        >
          <Groups id={params.id} from="usr" />
        </AccordionItem>
        <AccordionItem
          title="Direct Grants "
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="grants"
          iconName="sign-document"
        >
          <Grants id={params.id} tenantId={tenantId} from="usr" />
          
        </AccordionItem>
        <AccordionItem
          title="Tenant Level Grants"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="grantstenant"
          iconName="certificate"
        >
         
          <TenantGrants id={params.id} tenantId={tenantId} from="usr" />
          
        </AccordionItem>
        <AccordionItem
          title="Org Level Grants"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="grantsorg"
          iconName="clipboard-check"
        >
         
          <OrgGrants id={params.id} tenantId={tenantId} from="usr" />
          
        </AccordionItem>
      </div>
    </>
  );
}
