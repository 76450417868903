import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Loader, InputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import SelectDropdown from "../../components/Dropdown/select.js";
import {
  fetchUsersAPI,
  selectPage,
  setPageToNull,
  selectIsLoading,
  setSearchField,
  selectSearchField,
} from "../../app/userSlice.js";
import Overview from "../overview/Overview.js";

export function UserList() {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const loading = useSelector(selectIsLoading);
  const searchField = useSelector(selectSearchField);
  const page = useSelector(selectPage, shallowEqual);

  const dropdownOptions = [
    { label: "User ID", value: "userID" },
    { label: "User Name", value: "userName" },
    { label: "Email ID", value: "emailID" },
    { label: "Organization ID", value: "orgID" },
    { label: "Subject ID", value: "subjectID" },
  ];
  const [selectedOption, setSelectedOption] = useState("userName");

  const handleDropdownChange = (selected) => {
    setSelectedOption(selected.value);
  };
  let emailsTable = [];
  const [filterType, setFilterType] = useState('userName');
  useEffect(() => {
    dispatch(setSearchField(""));
    dispatch(fetchUsersAPI(20, null, true, null));
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page.data;
  const paginate = page.paginate;
  let backurl = "";
  const columns = [
    gridHeader(
      "Id",
      "id",
      (value, row) => (
        <Link to={`/usr/${row?.id}`} state={{ myObj: row }}>
          {value}
        </Link>
      ),
      null,
      "",
      null,
      null,
      null,
      "id"
    ),
    gridHeader(
      "User name",
      "userName",
      (value, row) => (
        <Link to={`/usr/${row?.id}`} state={{ myObj: row }}>
          {value}
        </Link>
      ),
      null,
      null,
      null,
      null,
      null,
      null
    ),
    gridHeader(
      "Name",
      "displayName",
      (value) => value,
      null,
      "",
      null,
      null,
      null,
      "displayName"
    ),
    // gridHeader(
    //   "Subject Id",
    //   "subjectId",
    //   (value) => value,
    //   null,
    //   "",
    //   null,
    //   null,
    //   null,
    //   "subjectId"
    // ),
    // gridHeader(
    //   "Organization",
    //   "organization",
    //   (value) => value,
    //   null,
    //   "",
    //   null,
    //   null,
    //   null,
    //   "organization"
    // ),
    // gridHeader(
    //   "Tenant",
    //   "defaultTenant",
    //   (value, row) =>
    //     row?.defaultTenant?.identifier ,
    //   null,
    //   "",
    //   null,
    //   null,
    //   null,
    //   "defaultTenant"
    // ),
    gridHeader(
      "Email",
      "emails",
      (value, row) =>
        row?.emails?.length > 1 ? (
          <div>
            <s-table-container class="no-border name-table">
              <table className="row-height-sm borderless">
                <tbody>
                  {Object.keys(row?.emails).map((v, idx) => {
                    emailsTable.push(
                      <tr key={row?.emails[idx]}>
                        <td className="pad-left-none">
                          {row?.emails[idx]?.value}
                        </td>
                      </tr>
                    );

                    const returnMap = emailsTable;
                    emailsTable = [];
                    return returnMap;
                  })}
                </tbody>
              </table>
            </s-table-container>
          </div>
        ) : (
          row?.emails?.length > 0 &&
          row?.emails[0] && <span>{row?.emails[0].value}</span>
        ),
      null,
      "",
      null,
      null,
      null,
      null
    ),
  ];

  let table = "";
  if (loading || submitting) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData?.length !== 0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  }

  const handleSearchBoxSubmit = useCallback(
    (e) => {
      if (e?.detail) dispatch(setSearchField(e?.detail?.value));
      dispatch(fetchUsersAPI(20, null, true, page?.paginate, selectedOption));
    },
    [dispatch, page.paginate, selectedOption]
  );

  const clearSearchBox = () => {
    dispatch(setSearchField(""));
    handleSearchBoxSubmit();
    
  };

  const handlePaginate = async (e) => {
    if (
      e.detail.currentPage !== 0 &&
      paginate.totalRecords > 0 &&
      e.detail.flag !== ""
    ) {
      setSubmitting(true);
      dispatch(
        fetchUsersAPI(
          e.detail.rowsPerPage,
          e.detail.startIndex,
          false,
          e.detail
        )
      );
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Overview />
      <Row>
        <Col className="pad-bottom-none">
          <h3 className="margin-bottom-none">Users</h3>
          <hr />
        </Col>
        <Col span="4" className="pad-bottom-none">
          <label>
            Search Users by User Name/SubjectID/OrganizationID/EmailID
          </label>
          <InputExtended
            inputId="namespace"
            placeholder="Search Users by User Name/SubjectID/OrganizationID/EmailID"
            type="search"
            onSSearch={(e) => handleSearchBoxSubmit(e)}
            onSClear={() => clearSearchBox()}
            value={searchField}
          />
        </Col>
        <Col span="1" className="pad-bottom-none">
          <label>Search Filter</label>
          <SelectDropdown
            options={dropdownOptions}
            value={selectedOption}
            onChange={handleDropdownChange}
            placeholder="Select Search Criteria"
            className="custom-dropdown"
          />
        </Col>
        <Col>
          {table}
          {pageData?.length > 0 ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginate.rowsPerPage}
              totalRecords={paginate.totalRecords}
              startIndex={paginate.startIndex}
              onSPaginate={(e) => {
                handlePaginate(e);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
