import React, { useState,useCallback, useEffect } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import {
  selectPage,
  selectIsGroupsLoading,
  fetchGroupsOrgAPI,
  setPageToNull,
} from "../../app/organizationSlice.js";
import { fetchGroupsUsrAPI } from "../../app/userSlice.js";
export function Groups(props) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsGroupsLoading);
  const page = useSelector(selectPage, shallowEqual);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (props?.id) {
      if (props?.from === "org")
        dispatch(fetchGroupsOrgAPI(props?.id, 20, null, true))
      .catch((err) => setError("Failed to fetch organization groups."));;

      if (props?.from === "usr")
        dispatch(fetchGroupsUsrAPI(props?.id, 20, null, true))
      .catch((err) => setError("Failed to fetch user groups."));
    }
    console.log("Fetching groups for ID:", props.id, "From:", props.from);
    return ()=>{
      dispatch(setPageToNull()); 
     
    }
   
  }, [dispatch,props.id, props.from]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page?.data || [];
  const paginate = page?.paginate || {};
  
    let columns="";
    if (pageData && pageData?.length>0){
     columns = [
      gridHeader("ID", "id", (value) => value),
      gridHeader("Display Name", "displayName", (value) => value || "", null, ""),
      gridHeader("Tenant ID", "tenantId", (value) => value || "", null, ""),
      gridHeader("Category", "category", (value) => value || "", null, ""),
      gridHeader("Scope", "scope", (value) => value || "", null, ""),
  ];
  if (props.from === "usr") {
    columns.push(gridHeader("Organization ID", "organizationId", (value) => value || "", null, ""));
}
}


 
    let table;
    if (loading || submitting) {
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <Loader id="page-loader" class="medium" aria-live="polite" loading />
            </div>
        );
    } else if (error) {
        table = (
            <div className="margin-top-sm text-red-600">
                <div className="font-semibold font-normal text-md">
                    {error}
                </div>
            </div>
        );
    } else if (pageData && pageData.length > 0) {
       
        const transformedData = pageData.map((group) => {
            const urn = group["urn:avalara:schemas:extension:2.0:AvalaraGroup"] || {}; 
            return {
                id: group.id ,
                displayName: group.displayName || "",
                tenantId: urn.tenant?.identifier || "",
                category: urn.category || "",
                scope: urn.scope || "",
                organizationId: props.from === "usr" ? urn.organization?.identifier || "" : undefined,
            };
        });

        table = (
            <s-table-container class="margin-top-xl th-with-filter">
                <DataGrid columns={columns} rows={transformedData} inContainer getKey={getRowKey} />
            </s-table-container>
        );
    } else {
        table = (
            <div className="margin-top-sm">
                <div className="font-semibold font-normal text-md">
                    No groups are found 
                </div>
            </div>
        );
    }
    const handlePaginate = async (e) => {
      const { currentPage, rowsPerPage, flag } = e.detail;
      if (
          currentPage !== 0 && paginate.totalRecords > 0 && flag !== ""
      ) {
          setSubmitting(true);

          dispatch(
              fetchGroupsOrgAPI(
                  props.id,
                  e.detail.rowsPerPage,
                  e.detail.startIndex,
                  false,
                  e.detail
              )
          );
          setSubmitting(false);
      }
  };
  return (
    <div>
      <Row>
        <Col>
          {table}
          
          {pageData && pageData?.length > 0 && loading==false ? (
            <Pagination
            className="margin-top-md"
            rowsPerPage={paginate.rowsPerPage } 
            totalRecords={paginate.totalRecords } 
            startIndex={paginate.startIndex } 
            currentPage={paginate.pageNumber}
            onSPaginate={(e) => handlePaginate(e)}
        />
    ) : null}
        </Col>
      </Row>
    </div>
  );
}
