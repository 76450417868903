import { createSlice } from "@reduxjs/toolkit";
import { buildURL } from "../shared/Utils";
import axios from "../axios";
import { getJwtToken } from "../app/sessionSlice";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    isloading: false,
    isTenantsLoading: false,
    isEntitlementsLoading: false,
    isUsersLoading: false,
    isAppsLoading: false,
    isGroupsLoading: false,
    searchField: "",
    totalData:[],
    page: {
      data: [],
      paginate: {
        pageNumber: 0,
        startIndex: -1,
        endIndex: 0,
        rowsPerPage: 0,
        totalRecords: 0,
      },
    },
    sortColumn: ["id", true],
    noDataType: "",
  },

  reducers: {
    setLoading: (state, action) => {
      state.isloading = action.payload;
    },
    setTotalData: (state, action) => {
      state.totalData = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageToNull: (state) => {
      state.page = {
        data: [],
        paginate: {
          pageNumber: 0,
          startIndex: -1,
          endIndex: 0,
          rowsPerPage: 0,
          totalRecords: 0,
        },
      };
    },
    setNoDataType: (state, action) => {
      state.noDataType = action.payload;
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setTenantsLoading: (state, action) => {
      state.isTenantsLoading = action.payload;
    },
    setEntitlementsLoading: (state, action) => {
      state.isEntitlementsLoading = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.isUsersLoading = action.payload;
    },
    setAppsLoading: (state, action) => {
      state.isAppsLoading = action.payload;
    },
    setGroupsLoading: (state, action) => {
      state.isGroupsLoading = action.payload;
    },
  },
});

export const {
  setLoading,
  setPage,
  setTotalData,
  setPageToNull,
  setSortColumn,
  setNoDataType,
  setSearchField,
  setTenantsLoading,
  setEntitlementsLoading,
  setUsersLoading,
  setAppsLoading,
  setGroupsLoading,
} = organizationSlice.actions;

export const selectPage = (state) => {
  return state.organization.page;
};

export const selectSortColumn = (state) => {
  return state.organization.sortColumn;
};

export const selectIsLoading = (state) => {
  return state.organization.isloading;
};

export const selectFormState = (state) => {
  return state.organization.formState;
};

export const selectNoData = (state) => {
  return state.organization.noDataType;
};
export const selectSearchField = (state) => {
  return state.organization.searchField;
};

export const selectIsTenantsLoading = (state) => {
  return state.organization.isTenantsLoading;
};
export const selectIsEntitlementsLoading = (state) => {
  return state.organization.isEntitlementsLoading;
};

export const selectIsUsersLoading = (state) => {
  return state.organization.isUsersLoading;
};

export const selectIsAppsLoading = (state) => {
  return state.organization.isAppsLoading;
};

export const selectIsGroupsLoading = (state) => {
  return state.organization.isGroupsLoading;
};

export const fetchOrganizationsAPI =
  (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (searchField) {
      filter = `displayName eq '${searchField.trim()}'`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };
    //   dispatch(
    //     setPage({
    //       data: response.data.items,
    //       paginate: paginateData,
    //     })
    //   );
    // }

    // dispatch(setLoading(false));

    
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
     
     
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => response.json())
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse))
       
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          setTotalData(resulted.items);
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 0,
                endIndex: top-1,
                rowsPerPage: top,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));




  };

export const fetchOrganizationByIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      //`${process.env.REACT_APP_API_HOST}/organizations/${id}?%24count=true`,
       `${process.env.REACT_APP_BASE_URL}/organizations/${id}?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };
    //   dispatch(
    //     setPage({
    //       data: response.data,
    //       paginate: paginateData,
    //     })
    //   );
    // }

    // dispatch(setLoading(false));


    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
     
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => response.json())
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse))
       
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex:0,
                endIndex: top-1,
                rowsPerPage: top,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));


  };

export const fetchOrganizationByNameAPI =
  (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (searchField) {
      filter = `displayName%20eq%20${searchField}`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations`,
       
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };
    //   dispatch(
    //     setPage({
    //       data: response.data.items,
    //       paginate: paginateData,
    //     })
    //   );
    // }
    // dispatch(setLoading(false));

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
     
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => response.json())
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse))
       
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 0,
                endIndex: top-1,
                rowsPerPage: top,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));




  };
  export const fetchGroupsOrgAPI =
  (id,top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const {searchField} = localState;
   
  
    dispatch(setGroupsLoading(true));
    
 
      let filter = searchField ? `&Filter=displayName eq '${searchField}'` : "";
    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/scim/v2/groups?Filter=urn:avalara:schemas:extension:2.0:AvalaraGroup.organization.identifier%20eq%20${id}`,
       
      filter,
      "",
      top,
      skip,
      null,
      true
       
    );
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
     
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
   

      fetch(apiURI, requestOptions)
      .then((response) => {
        
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((jsonresponse) => {
        console.log("Full API Response:", jsonresponse);
        console.log("Total Records:", jsonresponse.totalResults);
        if (!jsonresponse || !jsonresponse.resources) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
          return;
        }

        dispatch(setNoDataType(""));
        const totalRecords = jsonresponse.totalResults || 0;
        
        const paginateData = paginateDetails
        ? {
          pageNumber: paginateDetails.currentPage,
          startIndex: paginateDetails.startIndex ,
          endIndex: paginateDetails.endIndex,
          rowsPerPage: paginateDetails.rowsPerPage,
          totalRecords: totalRecords, 
      }
      : {
          pageNumber: 1,
          startIndex: 0,
          endIndex: top-1,
          rowsPerPage: top,
          totalRecords: totalRecords,  
      };

        dispatch(
          setPage({
            data: jsonresponse.resources,
            paginate: paginateData,
          })
        );
        dispatch(setGroupsLoading(false));
      })
      .then(() => console.log("API fetch successful"))
      .catch((error) => {
        console.error("API Request Error:", error);
        dispatch(setGroupsLoading(false));
      });





  };
export const fetchTenantsByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isTenantsLoading } = localState;

    if (isTenantsLoading) {
      return;
    }
    dispatch(setTenantsLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations/${id}/tenants?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    

    

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => response.json())
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse))
        console.log(resulted,"resulted tenant")
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 0,
                endIndex: top-1,
                rowsPerPage: top,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
              status:paginateData?.totalRecords && paginateData?.totalRecords>0 ? true: false,
            })
          );
        }
        dispatch(setTenantsLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));


  };

export const fetchUsersByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isUsersLoading } = localState;

    if (isUsersLoading) {
      return;
    }
    dispatch(setUsersLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations/${id}/users?%24count=true`,
      filter,
      "",
      top,
      skip,
      null,
      
      //sort
    );
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => {
        
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse))
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
          ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: resulted["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 0,
            endIndex: top-1,
            rowsPerPage: top,
            totalRecords: resulted["@recordsetCount"],
          };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }
         
        dispatch(setUsersLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };
//to get the entitlements
export const fetchEntitlement =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isEntitlementsLoading } = localState;

    if (isEntitlementsLoading) {
      return;
    }
    dispatch(setEntitlementsLoading(true));
    let filter = "";
    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/tenants/${id}/entitlements`,
      filter,
      "",
      top,
      skip
    );
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => response.json())
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse)) 
        console.log(resulted,"resulted")
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 0,
                endIndex: top-1,
                rowsPerPage: top,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }
        dispatch(setEntitlementsLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };
   
export const fetchAppsByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState();
    const { isAppsLoading } = localState.organization;
    if (isAppsLoading) {
      return;
    }
    dispatch(setAppsLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations/${id}/apps?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });
    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };

    //   dispatch(
    //     setPage({
    //       data: response.data.items,
    //       paginate: paginateData,
    //     })
    //   );
    // }

   
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch( apiURI, requestOptions)
      .then((response) => response.json())
      .then(jsonresponse=>{
        const resulted=JSON.parse(JSON.stringify(jsonresponse))
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 0,
                endIndex: top-1,
                rowsPerPage: top,
                totalRecords: resulted["@recordsetCount"],
              };
    
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }
         
        dispatch(setAppsLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };
export default organizationSlice.reducer;
